import { Button, Container, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, useToast, VStack } from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar'
import { usePostRegisterTeacherMutation } from "../redux/reducer/api"

type InputFormProps = {
  name: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  helper: string,
  error: string,
  validate: (value: string) => boolean
}

const InputForm: React.FC<InputFormProps> = (props: InputFormProps) => {
  const { name, value, helper, error, setValue, validate } = props

  return (
    <FormControl isRequired isInvalid={!validate(value) && value !== ""}>
      <FormLabel htmlFor={name}>{name}</FormLabel>
      <Input id={name} type={name} value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} />
      {
        !validate(value) ? (
          <FormHelperText>{helper}</FormHelperText>
        ) : (
          <FormErrorMessage>{error}</FormErrorMessage>
        )
      }
    </FormControl>
  )
}

export const TeacherRegister: React.FC = () => {
  const [name, setName] = useState("")
  const toast = useToast()
  const [register, { isLoading, isSuccess, isError }] = usePostRegisterTeacherMutation()
  useEffect(() => {

    if (!isLoading && isSuccess) toast({
      title: '저장 완료',
      description: '저장되었습니다!',
      status: 'success',
      duration: 9000,
      isClosable: true
    })

    if (!isLoading && isError) toast({
      title: '저장 실패',
      description: '제대로 입력되지 않았거나 이미 가입된 유저입니다!',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoading])

  return (
    <div>
      <Navbar active="teacherregister" />
      <Container maxW='container.lg' px={4} py={4} >
        <VStack spacing="20px" align="left">
          <VStack spacing="20px" align="left">
            <InputForm name="이름" value={name} setValue={setName} helper="이름을 입력하세요" error="이름을 입력하세요!"
              validate={(value: string) => /^[가-힣]{2,5}$/.test(value)} />
          </VStack>
          <Button colorScheme="purple"
            onClick={() => {
              const roles = []

              roles.push("admin")

              register({
                kind: "teacher",
                name: name,
              })
            }}
          >회원가입</Button>
        </VStack>
      </Container>
    </div >
  )
}