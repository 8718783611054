import {
  chakra,
  Container,
  VStack
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { BasicInfo } from '../components/BasicInfo'
import { Navbar } from '../components/Navbar'
import { useGetCurrentUserQuery } from '../redux/reducer/api'

export const Home: React.FC = () => {
  const { refetch } = useGetCurrentUserQuery()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div>
      <Navbar active="home" />
      <Container maxW='container.lg' px={4} py={4}>
        <VStack spacing={7} align="normal">
          <VStack spacing={3} align="normal">
            <chakra.h2 fontWeight="bold" fontSize="lg">
              현재 상태
            </chakra.h2>
            <BasicInfo />
          </VStack>
        </VStack>
      </Container>
    </div>
  )
}