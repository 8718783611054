import {
    chakra,
    Container,
    VStack
  } from '@chakra-ui/react'
  import React, { useEffect } from 'react'
  import { BasicInfo } from '../components/BasicInfo'
  import { Navbar } from '../components/Navbar'
  import { useGetCurrentUserQuery } from '../redux/reducer/api'
  
  export const RegisterSuccess: React.FC = () => {
    const { refetch } = useGetCurrentUserQuery()
  
    useEffect(() => {
      refetch()
    }, [])
  
    return (
      <div>
        <Navbar active="home"/>
        <Container maxW='container.lg' px={4} py={4}>
          <VStack spacing={7} align="normal">
            <VStack spacing={3} align="center">
              <chakra.h2 fontWeight="bold" fontSize="lg">
                회원가입에 성공했습니다!
                이용해 주셔서 감사합니다
              </chakra.h2>
            </VStack>
            <VStack spacing={3} align="normal">
              <chakra.h2 fontWeight="bold" fontSize="lg">
                회원정보
              </chakra.h2>
              <BasicInfo />
            </VStack>
          </VStack>
        </Container>
      </div>
    )
  }