import { BasePermission, Event, Place, Student, User, UserApplied } from "./types";

export function isNotUndefined<T>(x: T | undefined): x is T {
  return !!x
}

export function removeUndefined<T>(arr?: (T | undefined)[]): T[] {
  return arr ? arr.filter(isNotUndefined) : []
}

export function isStudent(user: User): user is User & Student{
  return user.kind === 'student'
}

export function composeAltCode(student: Student): string {
  const { grade, class_, number_ } = student
  return `${grade}${class_}${number_.toString().padStart(2, '0')}`
}

export function stringComparator(lhs?: string, rhs?: string): number {
  if ((lhs || "") > (rhs || "")) return 1
  if ((lhs || "") < (rhs || "")) return -1
  return 0
}

export function parseDate(v: string | Date): Date {
  if(v instanceof Date) return v
  return new Date(Date.parse(v))
}

export function sanitizeEvent(event: Event): Event {
  return {...event, start: parseDate(event.start), end: parseDate(event.end)}
}

export function intersect<T>(a: T[], b: T[]): T[] {
  const setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
}

export function isSatisfied(user: User, permission: BasePermission): boolean {
  if (permission.kinds && !permission.kinds.includes(user.kind)) return false
  if (permission.roles && !intersect(permission.roles, user.roles)) return false
  return true
}

export function isPossible(users: (User & UserApplied)[], place: Place, period: 1 | 2): boolean {
  if (!users.every((user) => isSatisfied(user, place.permission[period]))) return false

  const overlap = users.filter(user => (user.applied[period] || user.default[period]) === place.name).length
  return place.current[period] + users.length - overlap <= place.capacity[period]
}

export function IsHakIds(data: string[]): boolean {
  for (const hakId of data) {
    if (!/^[1-3][1-8](0[1-9]|1[0-7])$/.test(hakId)) return false
  }
  return true
}