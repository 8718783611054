import { Button, Container, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Input, Radio, RadioGroup, useToast, VStack } from '@chakra-ui/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../components/Navbar'
import { usePostRegisterStudentMutation } from "../redux/reducer/api"

type InputFormProps = {
  name: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  helper: string,
  error: string,
  validate: (value: string) => boolean
}

const InputForm: React.FC<InputFormProps> = (props: InputFormProps) => {
  const { name, value, helper, error, setValue, validate } = props

  return (
    <FormControl isRequired isInvalid={!validate(value) && value !== ""}>
      <FormLabel htmlFor={name}>{name}</FormLabel>
      <Input id={name} type={name} value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)} />
      {
        !validate(value) ? (
          <FormHelperText>{helper}</FormHelperText>
        ) : (
          <FormErrorMessage>{error}</FormErrorMessage>
        )
      }
    </FormControl>
  )
}

export const Register: React.FC = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const [hakId, setHakId] = useState("")
  const [date, setDate] = useState("")
  const [sex, setSex] = useState<"male" | "female">("male")

  const navigate = useNavigate()

  const toast = useToast()
  const [register, { isLoading, isSuccess, isError }] = usePostRegisterStudentMutation()
  useEffect(() => {

    if (!isLoading && isSuccess) {
      toast({
        title: '저장 완료',
        description: '저장되었습니다!',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
      navigate('/success')
    }
    if (!isLoading && isError) toast({
      title: '저장 실패',
      description: '제대로 입력되지 않았거나 이미 가입된 유저입니다!',
      status: 'error',
      duration: 9000,
      isClosable: true
    })
  }, [isLoading])

  return (
    <div>
      <Navbar active="register" />
      <Container maxW='container.lg' px={4} py={4} >
        <VStack spacing="20px" align="left">
          <VStack spacing="20px" align="left">
            <InputForm name="교번" value={code} setValue={setCode} helper="교번을 입력하세요" error="교번을 입력하세요!"
              validate={(value: string) => /^2[0-9][0-1][0-9][0-9]$/.test(value)}
            />
            <InputForm name="학번" value={hakId} setValue={setHakId} helper="학번을 입력하세요" error="학번을 입력하세요!"
              validate={(value: string) => /^[1-3][1-8][0-1][0-9]$/.test(value)
              } />
            <div>
              <FormLabel as='legend'>성별</FormLabel>
              <RadioGroup onChange={(s) => setSex(s as "male" | "female")} value={sex}>
                <HStack spacing='24px'>
                  <Radio value='male'>남자</Radio>
                  <Radio value='female'>여자</Radio>
                </HStack>
              </RadioGroup>
            </div>
            <InputForm name="이름" value={name} setValue={setName} helper="이름을 입력하세요" error="이름을 입력하세요!"
              validate={(value: string) => /^[가-힣]{2,5}$/.test(value)} />
            <InputForm name="전화번호" value={phone} setValue={setPhone} helper="전화번호를 '-'없이 입력하세요" error="전화번호를 '-'없이 입력하세요!"
              validate={(value: string) => /^010[0-9]{8}$/.test(value)} />
            <InputForm name="생년월일" value={date} setValue={setDate} helper="생년월일을 YYYY.MM.DD로 입력하세요" error="생년월일을 YYYY.MM.DD로 입력하세요"
              validate={(value: string) => /^20[0-9]{2}.(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value)} />
          </VStack>
          <Button colorScheme="purple"
            onClick={() => {
              const roles = []

              if (sex === "female") roles.push('area2')
              else roles.push('area1')

              roles.push('grade_' + hakId[0])
              roles.push('class_' + hakId[1])
              const num = Number(hakId[2] + hakId[3])
              if (num % 2 === 0) roles.push('even')
              else roles.push('odd')

              register({
                kind: "student",
                name: name,
                code: code,
                phone: phone,
                grade: Number(hakId[0]),
                class_: Number(hakId[1]),
                number_: Number(hakId[2] + hakId[3]),
                birthday: {
                  year: Number(date.split('.')[0]),
                  month: Number(date.split('.')[1]),
                  day: Number(date.split('.')[2])
                },
                roles: roles
              })
            }}
          >회원가입</Button>
        </VStack>
      </Container>
    </div >
  )
}